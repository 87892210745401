import React, { useCallback, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withTheme, minorScale, Pane, Text, Textarea, IconButton, Badge, Popover, Position, UnorderedList, ListItem, useTheme } from 'evergreen-ui';

import Page from '../../components/Page/Page';
import { actions } from '../../store/actions';
import { current } from '../../utils/selectors';
import { buildUserRecipeList, findRecipeAreaFromLocation } from '../../utils/functions';

// CAUTION: This component accesses the theme provided by Evergreen UI
//  This is NOT part of the documented functionality, and therefore
//  could break in future updates.
//  To fix: search for any references to theme.palette

const MessagesView = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const messages = useSelector((state) => _.sortBy(current(state, 'messages'), ['created']));
  const profile = useSelector((state) => state.profile);
  const hashLinks = useSelector((state) => _.fromPairs(
    _.map(buildUserRecipeList(state), (recipe) => {
      const area = findRecipeAreaFromLocation(recipe, state);
      const hash = `#${_.replace(recipe.name, / /g, '_')}`;
      const link = `/${area?.id}/front-of-house/recipes/${recipe.id}`;
      return [hash, link];
    })
  ));
  const [messageText, setMessageText] = useState('');
  const [autocompleteOpen, setAutocompleteOpen] = useState(false);
  const [autocompleteInput, setAutocompleteInput] = useState('');
  const bottomRef = useRef();

  const scrollBottom = useCallback((type = 'auto') => {
    if (bottomRef && bottomRef?.current) {
      bottomRef.current.scrollIntoView({
        behavior: type,
        block: "center",
        inline: "start",
      });
    }
  }, [bottomRef]);

  const autoCompleteOptions = useSelector((state) => {
    if (!autocompleteInput || !autocompleteOpen) {
      return false;
    }
    const recipes = buildUserRecipeList(state);
    return _.slice(_.filter(recipes, (item) => _.toLower(item.name).includes(_.toLower(autocompleteInput))),0, 10);
  });
  let prevSender = false;
  let prevDate = false;

  const renderMessageDate = (messageTimestamp) => {
    // Render the message date if it's more than one day later than the last message date rendered
    if (!messageTimestamp) {
      // Newly created messages have no timestamp
      // This gets corrected once the collection updates
      return;
    }
    const messageDate = moment(messageTimestamp.toDate());
    const dateDiff = messageDate.diff(prevDate, 'days');    // Returns NaN if prevDate is false
    
    if (isNaN(dateDiff) || dateDiff >= 1) {
      prevDate = messageDate;
      return (
        <Pane textAlign="center" marginBottom={minorScale(4)} marginTop={minorScale(4)}>
          <Badge>{messageDate.format('MMM D YYYY')}</Badge>
        </Pane>
      );
    }
    return null;
  };
  const renderMessageFrom = (message) => {
    if (message.fromId !== profile.id && message.fromId !== prevSender) {
      prevSender = message.fromId;
      return (<Text size={300}>{message.from}</Text>);
    }
    return null;
  };
  const getMessageType = (message) => {
    if (message.fromId !== profile.id) {
      return 'received';
    }
    return 'sent'
  };
  const sendMessage = () => {
    dispatch(actions.messages.addMessage({
      from: profile.name,
      fromId: profile.id,
      text: messageText.trim()
    }, () => scrollBottom('smooth')));
    setMessageText('');
  };
  const handleMessageChange = (newValue) => {
    if (autocompleteOpen) {
      // Find the last '#' character and set the autocomplete input to the string following it
      let inputIndex = newValue.lastIndexOf('#');
      if (inputIndex < 0) {
        // No '#' character found - close the autocomplete popover
        setAutocompleteOpen(false);
      }
      else {
        setAutocompleteInput(newValue.slice(inputIndex + 1));
      }
    }
    setMessageText(newValue);
  };
  const autoComplete = (rawValue) => {
    const autocompleteValue = _.replace(rawValue, / /g, '_');
    const message = messageText.slice(0, messageText.lastIndexOf('#') + 1) + autocompleteValue;
    setMessageText(message);
    setAutocompleteOpen(false);
    setAutocompleteInput('');
  }
  const handleKeyPress = (e) => {
    if (autocompleteOpen && e.key === 'Enter') {
      e.preventDefault();
      if (autoCompleteOptions.length === 1) {
        autoComplete(autoCompleteOptions[0].name);
      }
    }
    else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
    else if (e.key === '#') {
      setAutocompleteOpen(true);
    }
  };
  const renderAutoCompleteOptions = () => {
    if (!autocompleteInput) {
      return (<ListItem>Start typing the name of a recipe</ListItem>);
    }
    else {
      if (autoCompleteOptions.length) {
        return autoCompleteOptions.map((item) => (
          <ListItem key={item.id} onClick={() => autoComplete(item.name)} style={{ cursor: 'pointer' }}>{item.name}</ListItem>
        ));
      }
      return (<ListItem>No recipes match</ListItem>);
    }
  };
  const linkHash = (text) => {
    const tokens = text.split(' ');
    let newText = [];
    for (let i of tokens) {
      if (i[0] === '#') {
        if (hashLinks[i]) {
          newText.push(<Link to={hashLinks[i]} key={i}>{i}</Link>);
          newText.push(' ');
        }
        else {
          newText.push(<strong key={i}>{i}</strong>);
          newText.push(' ');
        }
      }
      else {
        newText.push(i + ' ');
      }
    }

    return newText;
  };

  useEffect(() => {
    const updateAccount = () => {
      dispatch(actions.messagesSeenTimes.messagesSeen(profile));
    }
    // update seen times
    updateAccount();
    scrollBottom();
    return () => {
      updateAccount();
    }
  }, [dispatch, profile, scrollBottom]);
  return (
    <Page title="Messages" containerProps={{ maxWidth: 600, background: 'tint2', display: 'flex', flexDirection: 'column', height: '100%', overflow: 'hidden' }} flex="1 0 auto">
     
      <Pane flex={`1 0 90%`} maxHeight={'100vh'} overflowY='scroll'>
        {messages && messages.map((message) => {
          const messageType = getMessageType(message);

          return (
            <Pane key={message.id}>
              {renderMessageDate(message.created)}
              {renderMessageFrom(message)}
              <Pane
                  display="flex"
                  justifyContent={(messageType === 'sent') ? 'flex-end' : 'flex-start'}
                  maxWidth="90%"
                  marginY={minorScale(2)}
              >
                <Pane
                    elevation={1}
                    background={(messageType === 'sent') ? theme.colors.secondary30 : '#ffffff'}
                    padding={minorScale(2)}
                    flexWrap='wrap'
                >
                  <Text width='100%' wordBreak='break-word' flexWrap='wrap'>{linkHash(message.text)}</Text>
                </Pane>
              </Pane>
            </Pane>
          );
        })}
        <div ref={bottomRef} />
      </Pane>
      <Pane display="flex" alignItems="center" paddingTop={minorScale(1)}>
        <Popover
            isShown={autocompleteOpen}
            position={Position.TOP_LEFT}
            onClose={() => setAutocompleteOpen(false)}
            content={
              <Pane paddingLeft={minorScale(2)} paddingRight={minorScale(2)} wordWrap='wrap'>
                <UnorderedList listStyle="none" marginLeft={0} padding={0}>
                  {renderAutoCompleteOptions()}
                </UnorderedList>
              </Pane>
            }
        >
          <Pane display='flex' flex='1' alignItem='center' maxWidth={500}>
            <Textarea 
                placeholder="Send message"
                flex="0 0 90%"
                value={messageText}
                minHeight="auto"
                maxHeight={80}
                rows={1}
                onKeyPress={(e) => handleKeyPress(e)}
                onChange={(e) => handleMessageChange(e.target.value)}
            />
            <IconButton margin={minorScale(1)} appearance="minimal" borderRadius="50%" icon="geolocation" iconSize={16} onClick={() => sendMessage()} />
          </Pane>
        </Popover>
      </Pane>
    </Page>
  )
};

export default withTheme(MessagesView);
