//React//
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom'; 
import { FixedSizeList as List } from 'react-window';
import { useMediaQuery } from 'react-responsive';

//Libraries
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Pane, Table, Text, Button, TextDropdownButton, TextInput, Select, majorScale, useTheme, Checkbox } from 'evergreen-ui';
import styled from 'styled-components';

//Components
import IconWrapper from '../../components/Icons/Icons';

//Files
import * as formats from '../../utils/format';
import { filterItems } from '../../utils/functions';

// Constants for sorting directions
const SORT_ASC = 'asc';
const SORT_DESC = 'desc';
const VIRTUAL_LIST_SIZE = 150;

// StyledRow component with dynamic grid and responsive hover effects
const StyledRow = styled(Table.Row)`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  align-items: center;
  overflow: scroll;
  cursor: default;
  transition: background-color 0.3s;
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  &:hover {
    background-color: #f5f5f5; // subtle hover effect
  }
  &:focus-within {
    outline: none;
    border-color: #0056b3;
  }
  @media (max-width: 460px) {
    grid-template-columns: repeat(12, 1fr); 
  }
`;

// StyledHeaderRow component with dynamic grid
const StyledHeaderRow = styled(Table.Head)`
  display: grid;
  grid-template-columns: repeat(16, 1fr);
  align-items: center;
  background-color: ${props => props.theme.colors.offwhite};
  height: 56px;
  position: sticky;  
  top: 0;            
  z-index: 3;       
  @media (max-width: 460px) {
    grid-template-columns: repeat(12, 1fr);
  }
`;

// StyledHeaderCell component
const StyledHeaderCell = styled(Table.HeaderCell)`
  display: grid;  
  align-items: center;
  justify-content: ${props => props.justifyContent || 'flex-start'};
  text-align: ${props => props.textAlign || 'left'};
  grid-column: span ${props => props.gridSpan || 1};
  grid-row: span ${props => props.gridRow || 1};
`;


// Component definition: DataTable accepts various props to customize its behavior and appearance
const DataTable = (props) => {
  const { headers, filters, items, onRowSelect, listHeight = 600, tableProps, emptyText, hideHeader, isTotals, isChecked } = props
  const theme= useTheme();
  const isMobile = useMediaQuery({ maxWidth: 460 });
  const isTablet = useMediaQuery({ minWidth: 461, maxWidth: 769 });
  const [sortField, setSortField] = useState(null);
  const [sortDir, setSortDir] = useState(SORT_ASC);
  const [itemSize, setItemSize] = useState(56); 

  // This optimization prevents unnecessary recalculations
  const sortedAndFilteredItems = useMemo(() => {
    const filteredItems = filterItems(items, filters);
    const orderedItems = _.orderBy(filteredItems, [sortField], [sortDir]);
    return orderedItems;
  }, [items, filters, sortField, sortDir]);

  const itemData = useMemo(() => ({
    items: sortedAndFilteredItems,
    headers: headers,
    onRowSelect: onRowSelect,
    isMobile: isMobile,
    isTablet: isTablet,
  }), [sortedAndFilteredItems, headers, onRowSelect, isMobile, isTablet]);

  //ROW
  // Set up row size based on device type using useEffect
  useEffect(() => {
    if (isMobile) {
      setItemSize(64); // Smaller size for mobile
    } else if (isTablet) {
      setItemSize(64); // Intermediate size for tablets
    } else {
      setItemSize(64); // Default size for desktop
    }
  }, [isMobile, isTablet]);

  // Render function for react-window's fixed size list
  const renderRowFixedSizeList = ({ index, data, style }) => {
    const item = data.items[index];
    if (!item) {
      console.error("No item at index", index);
      return null;
    }

    const backgroundColor = data.isTotals ? theme.colors.offwhite : 'transparent';

    return (
      <StyledRow 
        key={item.id} 
        //border="1px solid green"
        style={style}
        columns={data.headers.map(header => header.width)}
        onClick={() => data.onRowSelect(item)}
        backgroundColor={backgroundColor}
      >
        {data.headers && data.headers.map(header => renderCell(item, header))}
      </StyledRow>
    );
  };

  // Standard row render function for non-virtualized lists
  const renderRow = (item) => {
    let rowSize = 64 //isMobile ? 56 : isTablet ? 56 : 64;
    const backgroundColor = isTotals ? theme.colors.offwhite : 'transparent';

    return (
      <StyledRow
        key={item.id}
        size={rowSize}
        //border="1px solid red"
        columns={headers.map(header => header.width)}
        onClick={() => onRowSelect(item)}
        backgroundColor={backgroundColor}
      >
        {headers && headers.map(header => renderCell(item, header))}
      </StyledRow>
    );
  };


  //CELL
  // Function to render each cell in a row based on the column's header configuration
  const renderCell = (item, header) => {
    //console.log('Rendering cell for item:', item, 'and header:', header);
    let { textProps } = header;
    let cellProps = {};
    let cellContents;

    const doAction = (action, item) => {
      const fnName = 'on' + _.capitalize(action);
      if (typeof props[fnName] === 'function') {
        props[fnName](item);
      } else {
        console.warn(`Function not found for ${action} action!`);
      }
    };     

    // Define rendering logic for different cell types (e.g., action, input, dropdown)
    switch (header.type) {
      case 'action':
        cellProps.justifyContent = 'center';
        cellProps.flexBasis = 75;
        cellProps.flexGrow = 0;
        if (item.hideLink) {}
        else if (header.link) {
          cellContents = (
            <IconWrapper
                appearance="clickable"
                name={header.icon || header.field}
                is={Link}
                to={`${header.link}${item.id}`}
                {...header.buttonProps}
            />
          );
        }
        else {
          cellContents = (
            <IconWrapper
                appearance="clickable"
                name={header.icon || header.field}
                onClick={() => doAction(header.field, item)}
                {...header.buttonProps}
            />
          );
        }
        break;

      case 'actionField':
        cellProps.justifyContent = 'flex-start';
        cellContents = (
          <Button
              appearance="minimal"
              onClick={() => doAction(header.action)}
          >{renderCellValue(item, header)}</Button>
        );
        break;
      
      /*case 'actionCheck':
      const isChecked = item[header.field];
      cellProps.justifyContent = 'flex-start';
      cellContents = (
        <Checkbox
          checked={isChecked}
          appearance="minimal"
          onClick={(e) => { e.stopPropagation(); doAction(header.field, item); }}
        >
          {renderCellValue(item, header)}
        </Checkbox>
      );
      break;*/

      case 'thumbnail':
        cellProps.justifyContent = 'center';
        cellProps.flexBasis = 120;
        cellProps.flexGrow = 0;
        cellContents = !_.isEmpty(item[header.field]) && (
          <Pane
              minHeight={56}
              height="100%"
              width="100%"
              background={`url(${item[header.field]}) no-repeat center center`}
          ></Pane>
        );
        break;

      case 'input':
        cellContents = (
         <TextInput
              value={item[header.field] || ''}
              onChange={(e) =>header.onChange(item, e)}
              {...header.inputProps}
              width="100%"
          /> 
        );
        break;

      case 'dropdown':
        cellContents = (
          <Select value={item[header.field] || ''} onChange={(e) => header.onChange(item, e)} {...header.inputProps}>
            {_.map(header.options || header.optionsFn(item), (opt, index) => (
              <option value={opt.value} key={index}>{opt.label}</option>
            ))}
          </Select>
        );
        break;

      case 'link':
        cellContents = (
          <Link to={`${header.link}${item.id}`}><Text>{renderCellValue(item, header)}</Text></Link>
        );
        break;

      case 'numeric':
        cellProps.justifyContent = 'flex-start';
        cellProps.textAlign = 'left';
        cellContents = (
          <Text {...textProps}>{renderCellValue(item, header)}</Text>
        );
        break;

      default:
        cellContents = (
          <Text 
            color={theme.colors.black} 
            fontSize={isMobile ? "13px" : "14px"} 
            {...textProps}
          >
            {renderCellValue(item, header)}
          </Text>
        );
        break;
    }
    // Apply grid column and row span
    cellProps = {
      ...cellProps,
      style: { 
        ...cellProps.style,
        gridColumn: `span ${header.width}`, 
        gridRow: `span ${header.row || 1}` 
      }
    };

    return (
      <Table.Cell
        key={`${item.id}-${header.field || header.label}`}
        {...cellProps}
      >
        {cellContents}
      </Table.Cell>
    );
  };

  const getNestedValue = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
  };
  
  const renderCellValue = (item, header) => {
    let cellValue = header.type === 'calc' ? header.calc(item) : getNestedValue(item, header.field);
  
    const defaultValue = (header.type === 'numeric' || header.type === 'calc') ? 0 : '';
    cellValue = (cellValue !== undefined && cellValue !== null && cellValue !== '') ? cellValue : defaultValue;
  
    if (header.type === 'numeric') {
      cellValue = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(cellValue);
    }
  
    if (typeof header.format === 'function') {
      cellValue = header.format(cellValue);
    } else if (header.format && formats[header.format]) {
      cellValue = formats[header.format](cellValue);
    }
  
    if (header.format === 'currency' && cellValue === '') {
      cellValue = '0.00';
    }
  
    if (header.prefix) {
      cellValue = `${header.prefix}${cellValue}`;
    }
    if (header.suffix) {
      cellValue = `${cellValue}${header.suffix}`;
    }
  
    return cellValue;
  };

  //HEADER
  const renderHeaderCell = (header, index, hideHeader) => {
    const isSortable = header.type !== 'action' && header.type !== 'thumbnail';
  
    let headerStyle = {
      fontSize: "14px",
      color: theme.colors.black,
      fontWeight: "400",
      ...header.style,
    };
  
    // Define common properties for sortable headers, including the onClick logic
    let buttonProps = {
      // Default icon is empty to avoid showing a caret when not sorted
      name: (<Pane></Pane>), 
      // Clicking the header will toggle sort direction or change the sort field
      onClick: () => {
        if (sortField === header.field) {
          setSortDir(sortDir === SORT_ASC ? SORT_DESC : SORT_ASC);
        } else {
          setSortField(header.field);
          setSortDir(SORT_ASC);
        }
      },
      style: headerStyle,
      justifyContent: 'flex-start',
      textAlign: 'left',
      ...(header.columnProps || {}),
    };
  
    // Update the icon to indicate the current sort direction
    if (header.field === sortField) {
      buttonProps.icon = sortDir === SORT_ASC ? 'caretUp' : 'caretDown';
    }
  
    // Apply specific alignment and sizing for numeric and action column types
    if (header.type === 'numeric') {
      buttonProps.justifyContent = 'flex-start';
      buttonProps.textAlign = 'left';
    } else if (header.type === 'action' || header.type === 'thumbnail') {
      // Center-align the contents and adjust the sizing for action and thumbnail headers
      buttonProps.justifyContent = 'flex-start';
      buttonProps.flexBasis = header.type === 'thumbnail' ? 120 : 75;
      buttonProps.flexGrow = 0;
    }
  
    // Render a TextDropdownButton for sortable headers or a simple Text component otherwise
    return (
      <StyledHeaderCell
        key={index}
        gridSpan={header.width} 
        gridRow={header.row || 1} 
        hideHeader={hideHeader}
      >
        {isSortable ? 
          <TextDropdownButton {...buttonProps}>{header.label}</TextDropdownButton>
          : <Text style={headerStyle}>{header.label}</Text>}
      </StyledHeaderCell>
    );
  }

  return (
    <Pane margin={0} padding={0}>
      <Table {...tableProps} margin={0} padding={0}>
        {/* Render table headers */}
        {!hideHeader && (
          <StyledHeaderRow columns={headers.map(header => header.width)} hideHeader={hideHeader} theme={theme}>
            {headers.map((header, index) => renderHeaderCell(header, index, hideHeader))}
          </StyledHeaderRow>
        )}

        {/* Conditionally use virtualization for rendering rows */}
        {sortedAndFilteredItems.length > VIRTUAL_LIST_SIZE ? (
          <List 
            height={listHeight} 
            itemCount={sortedAndFilteredItems.length}
            itemSize={itemSize} 
            width="100%"
            itemData={itemData}
          >
            {renderRowFixedSizeList}
          </List>
        ) : (
          <Table.Body margin={0} padding={0}>
            {sortedAndFilteredItems.map((item) => renderRow(item))}
          </Table.Body>
        )}
      </Table>

      {/* Display a message if no items are available */}
      {sortedAndFilteredItems.length === 0 && (
        <Pane display="flex" alignItems="center" justifyContent="start" marginLeft={majorScale(2)} height="56px">
          <Text>{emptyText}</Text>
        </Pane>
      )}
    </Pane>
  );
};

// Define PropTypes for DataTable component
DataTable.propTypes = {
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  filters: PropTypes.object,
  onRowSelect: PropTypes.func,
  tableProps: PropTypes.object,
  emptyText: PropTypes.string,
  isMobile: PropTypes.bool,
  hideHeader: PropTypes.bool,
  isTotals: PropTypes.bool, 
};

// Define default props for DataTable component
DataTable.defaultProps = {
  emptyText: 'No data to display',
  onRowSelect: () => {},
  hideHeader: false,
  isTotals: false,
};

export default DataTable;
